<template>
  <div class="content-page">
    <template v-if="content?.[0]?.sections">
      <render-sections :content="content[0].sections" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ContentPage } from "@shop-storefront/utils";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { contentPageReferences } from "~/utils/contentstack/constants/references.constants";
import { resolvePageSeo } from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.resolvers";

const { $contentstack } = useNuxtApp();
const {
  params: { slug },
  fullPath,
} = useRoute();

const { data: content } = await useAsyncData("content", async () => {
  // if we got multiple url segments, the slug is always the last part of the url
  const url = Array.isArray(slug) ? slug[slug.length - 1] : slug;

  return await $contentstack.getEntries<ContentPage>({
    type: ContentType.contentPage,
    includeReference: contentPageReferences as string[],
    customQuery: {
      [ContentstackPageParams.slug]: url,
    },
  });
});

if (!content.value?.length) {
  const { show404Page } = useErrorPage();
  show404Page(ErrorPageType.content, fullPath);
}

if (content?.value?.[0]?.seo) {
  useHead(resolvePageSeo(content.value[0].seo));
}
</script>
